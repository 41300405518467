<template>
  <b-card class="report-filter">
    <b-row>
      <b-col cols="12" md="6" lg="3">
        <b-form-group
          :label="$t('rPayment_s_datesRange')"
          label-size="sm"
        >
          <date-range
            id="filter-input-dates"
            v-model="dates_range"
            :max-date="maxDate"
            style="max-height: 31px;"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_customerType')"
          label-size="sm"
        >
          <b-form-select
            v-model="user_type"
            :options="filterData.user_type"
            size="sm"
            label-field="text"
            value-field="id"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_purchaseStatus')"
          label-size="sm"
        >
          <b-form-select
            v-model="status"
            :options="filterData.status"
            size="sm"
            label-field="text"
            value-field="id"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_country')"
          label-size="sm"
        >
          <b-form-select
            v-model="country"
            :options="filterData.country"
            size="sm"
            label-field="text"
            value-field="id"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="2">
        <b-form-group
          :label="$t('rPayment_s_platform')"
          label-size="sm"
        >
          <b-form-select
            v-model="platform"
            :options="filterData.platform"
            size="sm"
            label-field="text"
            value-field="id"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-end">
        <b-btn variant="primary" size="sm" class="mr-2" @click="onSubmit">
          Submit
        </b-btn>
        <b-btn variant="secondary" size="sm" @click="resetFilter">
          Reset
        </b-btn>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import * as moment from 'moment'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'PaymentsReportFilter',
  data () {
    return {
      maxDate: moment().startOf('day').format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapFields({
      filterData: 'reports.paymentsReport.filterData',
      dates_range: 'reports.paymentsReport.filter.dates_range',
      user_type: 'reports.paymentsReport.filter.user_type',
      status: 'reports.paymentsReport.filter.status',
      platform: 'reports.paymentsReport.filter.platform',
      country: 'reports.paymentsReport.filter.country',
      club_member: 'reports.paymentsReport.filter.club_member',
      page: 'reports.paymentsReport.page'
    })
  },
  mounted () {
    this.fetchFilterData()
    if (Object.keys(this.$route.query).length > 0) {
      Object.keys(this.$route.query)
        .forEach((k) => {
          switch (k) {
            case 'start_date':
              this.dates_range.from = this.$route.query[k]
              break
            case 'finish_date':
              this.dates_range.to = this.$route.query[k]
              break
            default:
              break
          }
        })
      this.onSubmit()
    }
  },
  methods: {
    ...mapActions({
      fetchFilterData: 'reports/paymentsReport/fetchFilterData',
      resetFilter: 'reports/paymentsReport/resetFilter'
    }),
    onSubmit () {
      this.page = 1
      this.$nextTick(() => {
        this.$emit('submit')
      })
    }
  }
}
</script>

<style>
  .month-picker-input-container {
    position: initial;
  }
  .month-picker-input {
    margin: 0 !important;
  }
  .month-picker__container {
    z-index: 10;
  }
</style>
